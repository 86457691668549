buttons:
  pureLoginOut: LoginOut
  pureLogin: Login
  pureOpenSystemSet: Open System Configs
  pureReload: Reload
  pureCloseCurrentTab: Close CurrentTab
  pureCloseLeftTabs: Close LeftTabs
  pureCloseRightTabs: Close RightTabs
  pureCloseOtherTabs: Close OtherTabs
  pureCloseAllTabs: Close AllTabs
  pureContentFullScreen: Content FullScreen
  pureContentExitFullScreen: Content ExitFullScreen
  pureClickCollapse: Collapse
  pureClickExpand: Expand
  pureConfirm: Confirm
  pureSwitch: Switch
  pureClose: Close
  pureBackTop: BackTop
  pureOpenText: Open
  pureCloseText: Close
search:
  pureTotal: Total
  pureHistory: History
  pureCollect: Collect
  pureDragSort: （Drag Sort）
  pureEmpty: Empty
  purePlaceholder: Search Menu
panel:
  pureSystemSet: System Configs
  pureCloseSystemSet: Close System Configs
  pureClearCacheAndToLogin: Clear cache and return to login page
  pureClearCache: Clear Cache
  pureOverallStyle: Overall Style
  pureOverallStyleLight: Light
  pureOverallStyleLightTip: Set sail freshly and light up the comfortable work interface
  pureOverallStyleDark: Dark
  pureOverallStyleDarkTip: Moonlight Overture, indulge in the tranquility and elegance of the night
  pureOverallStyleSystem: Auto
  pureOverallStyleSystemTip: Synchronize time, the interface naturally responds to morning and dusk
  pureThemeColor: Theme Color
  pureLayoutModel: Layout Model
  pureVerticalTip: The menu on the left is familiar and friendly
  pureHorizontalTip: Top menu, concise overview
  pureMixTip: Mixed menu, flexible
  pureStretch: Stretch Page
  pureStretchFixed: Fixed
  pureStretchFixedTip: Compact pages make it easy to find the information you need
  pureStretchCustom: Custom
  pureStretchCustomTip: Minimum 1280, maximum 1600
  pureTagsStyle: Tags Style
  pureTagsStyleSmart: Smart
  pureTagsStyleSmartTip: Smart tags add fun and brilliance
  pureTagsStyleCard: Card
  pureTagsStyleCardTip: Card tags for efficient browsing
  pureTagsStyleChrome: Chrome
  pureTagsStyleChromeTip: Chrome style is classic and elegant
  pureInterfaceDisplay: Interface Display
  pureGreyModel: Grey Model
  pureWeakModel: Weak Model
  pureHiddenTags: Hidden Tags
  pureHiddenFooter: Hidden Footer
  pureMultiTagsCache: MultiTags Cache
menus:
  pureHome: Home
  pureLogin: Login
  pureAbnormal: Abnormal Page
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  purePermission: Permission Manage
  purePermissionPage: Page Permission
  purePermissionButton: Button Permission
  developerButton: developer Manage
  programPackageButton: Program Package Manage
  developerPkgUpgradButton: Package Upgrade
  productButton: Product Manage
  productStacksButton: Product Stacks
  productProductButton: Product
  productFeaturnTypeButton: Feature Type
  productBrandButton: Product Brand
  developerFeatureButton: Feature
  developerFeatureAppButton: Feature App
  systemManageButton: System Manage
  menuManageButton: Menu
  psnManageButton: Product Stacks Node
status:
  pureLoad: Loading...
  pureMessage: Message
  pureNotify: Notify
  pureTodo: Todo
  pureNoMessage: No Message
  pureNoNotify: No Notify
  pureNoTodo: No Todo
login:
  pureUsername: Username
  purePassword: Password
  pureLogin: Login
  pureLoginSuccess: Login Success
  pureLoginFail: Login Fail
  pureUsernameReg: Please enter username
  purePassWordReg: Please enter password
  purePassWordRuleReg: The password format should be any combination of 8-18 digits
product_menu:
  feature_type: Feature Type
  brand: Brand
  menu: Menu
  app: Application
  advanced_package: Advanced Package
  menu_search_tips: The text was not found (please search for individual text. For example, if the text is "Freescale\9S12G", search for "Freescale" or "9S12G")
public:
  search: Search
  loading: Loading
  no_data: No Data
product:
  product_exist: The product does not exist
