// 标识符正则
//export const FlagValilPattern = /^[A-Z_\-0-9]+$/;
// 标识符正则
export const FlagValilPattern = /^[A-Za-z]+\w+$/;
// 程序包标识符正则
export const PkgFlagValil = {
  pattern: /^[A-Z][A-Z0-9]*(_[A-Z0-9]+)*$/,
  msg: "大写字母、数字、下划线组成并且必须以大写字母开头，并且不能以下划线结尾",
  len: {
    min: 1,
    max: 50,
    message: "长度必须在 1 到 50 之间",
    trigger: "blur"
  },
  len_30: {
    min: 1,
    max: 30,
    message: "长度必须在 1 到 30 之间",
    trigger: "blur"
  }
};
export const psnPattern = /^\d{8}$/; // psn验证
export const idPattern = /^\d+$/; // id验证

function validPattern(str, pattern) {
  return pattern.test(str);
}

export const psnPatternValid = str => validPattern(str, psnPattern);
export const idPatternValid = str => validPattern(str, idPattern);

// 产品分类type配置
export const productStacksTypeConfig = [
  { label: "可发布产品", value: 0 },
  { label: "配件", value: 1 }
];

// 获取基础数据的 label
const getConfigDataLabel = (baseData, val, key = "label") => {
  let retVal = baseData.find(item => item.value === val);

  if (retVal) {
    return retVal.label;
  }

  return retVal;
};

// 获取产品分类 label
export const getProductStacksTypeLabel = val =>
  getConfigDataLabel(productStacksTypeConfig, val);

// 产品type 配置
export const productTypeConfig = [
  { label: "测试机", value: 0 },
  { label: "正式机", value: 1 }
];

// 获取产品分类 label
export const getProductTypeLabel = val =>
  getConfigDataLabel(productTypeConfig, val);

// 品牌地区
export const brandRegionConfig = [
  { label: "中国地区", value: "1" },
  { label: "欧洲地区", value: "2" },
  { label: "美洲地区", value: "3" },
  { label: "亚洲地区", value: "4" },
  { label: "其他地区", value: "5" }
];

// 获取品牌地区 label
export const getBrandRegionLabel = val =>
  getConfigDataLabel(brandRegionConfig, val);

// 品牌地区
export const softwarePkgTypeConfig = [
  { label: "销售用软件包", value: 1 },
  { label: "为定制软件包", value: 2 },
  { label: "为推送用软件包", value: 3 }
];

// 获取品牌地区 label
export const getSoftwarePkgTypeLabel = val =>
  getConfigDataLabel(softwarePkgTypeConfig, val);

// 菜单 是否隐藏菜单配置
export const menuShowLinkConfig = [
  { label: "否", value: 1 },
  { label: "是", value: 0 }
];

// 菜单 是否隐藏菜单配置 label
export const getmenuShowLinkLabel = val =>
  getConfigDataLabel(menuShowLinkConfig, val);

// 菜单 是否隐藏菜单配置
export const moduleTypeConfig = [
  { label: "外部", value: 1 },
  { label: "内部", value: 0 }
];

// 菜单 是否隐藏菜单配置 label
export const getModuleTypeConfigLabel = val =>
  getConfigDataLabel(moduleTypeConfig, val);

// 系统
export const SystemTypeConfig = [
  { label: "领世达中心", value: 1 },
  { label: "翻译中心", value: 2 }
];

// 系统  label
export const getSystemTypeConfigLabel = val =>
  getConfigDataLabel(SystemTypeConfig, val);

// 当前系统
export const currentSystemConfig = 1;

// 状态
export const statusConfig = [
  { label: "启用", value: 0 },
  { label: "禁用", value: 9 }
];

// 状态  label
export const getStatusConfigLabel = val =>
  getConfigDataLabel(statusConfig, val);

// 权限类型
export const permissionTypeConfig = [
  { label: "菜单", value: 1 },
  { label: "产品", value: 2 },
  { label: "程序包", value: 3 }
];

// 权限类型  label
export const getPermissionTypeLabel = val =>
  getConfigDataLabel(permissionTypeConfig, val);

// 编码类型
export const codeingTypeConfig = [
  { label: "LF", value: "LF" },
  { label: "RF", value: "RF" }
];

// 编码类型  label
export const getCodeingTypeLabel = val =>
  getConfigDataLabel(codeingTypeConfig, val);

// app 状态
export const appStatusConfig = [
  { label: "启用", value: 0, resLabel: "已启用" },
  { label: "开发中", value: 1, resLabel: "开发中" },
  { label: "禁用", value: 9, resLabel: "已禁用" }
];

// app 状态  label
export const getAppStatusLabel = val =>
  getConfigDataLabel(appStatusConfig, val);

// app 状态  label
export const getAppStatusResLabel = val =>
  getConfigDataLabel(appStatusConfig, val, "resLabel");

// app 状态
export const menuConfTempConfig = [
  { label: "传递模版", value: 1 },
  { label: "展示模版", value: 2 },
  { label: "配置模版", value: 3 }
] as const;

// app 状态  label
export const getMenuConfTempLabel = val =>
  getConfigDataLabel(menuConfTempConfig, val);

// 阿里图标的使用方式
export const aliIconUseConfig = [
  { label: "font_class", value: "font_class" },
  { label: "symbol", value: "symbol" }
] as const;

export const getaliIconUseLabel = val =>
  getConfigDataLabel(aliIconUseConfig, val);

// psn 状态
export const psnStatusConfig = [
  { label: "未编号", value: 0 },
  { label: "已编号", value: 1 },
  { label: "已同步", value: 2 },
  { label: "已入库", value: 3 },
  { label: "预出库", value: 4 },
  { label: "已出库", value: 5 },
  { label: "已发货", value: 6 },
  { label: "作废", value: 9 }
] as const;

// 视频教学 状态
export const videoInstructionTypeConfig = [
  { label: "实车操作", value: 1 },
  { label: "产品教学", value: 2 }
] as const;

// 视频教学 状态  label
export const getVideoInstructionTypeLabel = val =>
  getConfigDataLabel(videoInstructionTypeConfig, val);

export const getPsnStatusLabel = val =>
  getConfigDataLabel(psnStatusConfig, val);

// 订单状态
export const orderStatusConfig = [
  { label: "待出库", value: 0 },
  { label: "已完全出库", value: 1 },
  { label: "已发货", value: 2 },
  { label: "已取消", value: 5 }
] as const;

// app 状态  label
export const getOrderStatusLabel = val =>
  getConfigDataLabel(orderStatusConfig, val);

// 订单状态
export const returnGoodsStatusConfig = [
  { label: "待完成", value: 0 },
  { label: "已完成", value: 1 }
] as const;

// app 状态  label
export const getReturnGoodsStatusLabel = val =>
  getConfigDataLabel(returnGoodsStatusConfig, val);

// 订单状态
export const dealerTypeConfig = [
  { label: "全部", value: 0 },
  { label: "国内经销商", value: 1 },
  { label: "国外经销商", value: 2 },
  { label: "特殊计算用户", value: 3 },
  { label: "GMT私有用户", value: 4 }
] as const;

// app 状态  label
export const getDealerTypeConfigLabel = val =>
  getConfigDataLabel(dealerTypeConfig, val);

// 完成情况
export const completionStatusConfig = [
  { label: "按时完成", value: 1 },
  { label: "延期完成", value: 2 },
  { label: "提前完成", value: 3 }
] as const;

// 完成情况  label
export const getCompletionStatusLabel = val =>
  getConfigDataLabel(completionStatusConfig, val);

// 完成质量
export const completionQualityStatusConfig = [
  { label: "正常", value: 1 },
  { label: "一般", value: 2 },
  { label: "不合格", value: 3 }
] as const;

// 完成质量  label
export const getCompletionQualityStatusLabel = val =>
  getConfigDataLabel(completionQualityStatusConfig, val);

// 评价状态
export const evaluationStatusConfig = [
  { label: "待评价", value: 0 },
  { label: "已评价", value: 1 }
] as const;

// 评价状态  label
export const getEvaluationStatusLabel = val =>
  getConfigDataLabel(evaluationStatusConfig, val);
